import {createApp, defineAsyncComponent} from 'vue';
import VueCookies from 'vue-cookies';
import { ZiggyVue } from "ziggy/dist/vue.es.js";
import axios from 'axios';
import _ from 'lodash';

import Form from './classes/Form';
import Errors from './classes/Errors';
import Flash from "crudhub/Classes/Flash.js";

import * as Sentry from '@sentry/vue';

// global variables
window.Vue = 'vue';
window.axios = axios;
window._ = _;
window.Form = Form;
window.Errors = Errors;
window.Flash = Flash;

// axios config
window.axios.defaults.baseURL = '/ajax';
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

// Vue app init
const app = createApp({});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.replayIntegration(),
      ],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.001,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'vite-dev',
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0.001,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE || 0.001,
});

app.use(VueCookies);
app.use(ZiggyVue);

// Define async components
const asyncComponents = {
    'start-rca': defineAsyncComponent(() => import('./components/StartRca.vue')),
    'form-rca': defineAsyncComponent(() => import('./components/FormRca.vue')),
    'form-asr': defineAsyncComponent(() => import('./components/FormAsr.vue')),
    'form-home': defineAsyncComponent(() => import('./components/FormHome.vue')),
    'form-travel': defineAsyncComponent(() => import('./components/FormTravel.vue')),
    'form-testimonial': defineAsyncComponent(() => import('./components/FormTestimonial.vue')),
    'form-complaint': defineAsyncComponent(() => import('./components/FormComplaint.vue')),
    'form-feedback': defineAsyncComponent(() => import('./components/FormFeedback.vue')),
    'shopping-cart': defineAsyncComponent(() => import('./components/ShoppingCart.vue')),
    'vehicle-add': defineAsyncComponent(() => import('./components/VehicleAdd.vue')),
    'account-details': defineAsyncComponent(() => import('./components/account/AccountDetails.vue')),
    'account-orders': defineAsyncComponent(() => import('./components/account/AccountOrders.vue')),
    'account-order': defineAsyncComponent(() => import('./components/account/AccountOrder.vue')),
    'account-vehicles': defineAsyncComponent(() => import('./components/account/AccountVehicles.vue')),
    'account-houses': defineAsyncComponent(() => import('./components/account/AccountHouses.vue')),
    'account-alerts': defineAsyncComponent(() => import('./components/account/AccountAlerts.vue')),
    'paid-policy': defineAsyncComponent(() => import('./components/policies/PaidPolicy.vue')),
};

// Register components
for (const component in asyncComponents) {
    app.component(component, asyncComponents[component]);
}

app.mount('#app');
